import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.index-of.js";
/* this file is used to create the product slider on the product page, we use canvas to analyze and fit the scale of the product image */
var findBorders = function () {
  function rowBlank(imageData, width, y) {
    for (var x = 0; x < width; ++x) {
      if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false;
    }
    return true;
  }
  function columnBlank(imageData, width, x, top, bottom) {
    for (var y = top; y < bottom; ++y) {
      if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false;
    }
    return true;
  }
  return function (img) {
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var imageData = ctx.getImageData(0, 0, img.width, img.height);
    var top = 0,
      bottom = imageData.height,
      left = 0,
      right = imageData.width;
    while (top < bottom && rowBlank(imageData, img.width, top)) ++top;
    while (bottom - 1 > top && rowBlank(imageData, img.width, bottom - 1)) --bottom;
    while (left < right && columnBlank(imageData, img.width, left, top, bottom)) ++left;
    while (right - 1 > left && columnBlank(imageData, img.width, right - 1, top, bottom)) --right;
    return {
      left: left,
      right: right,
      top: top,
      bottom: bottom
    };
  };
}();
function calculateScale(img) {
  var default_Scale = 1.1;
  var max_scale = 1.72;
  var adjustScale = 0.05;
  var ratioThreshold = 0.2; // 10% deviation is allowed

  var borders = findBorders(img);
  var horizontalContent = img.width - (borders.left + (img.width - borders.right));
  var verticalContent = img.height - (borders.top + (img.height - borders.bottom));
  var scaleFactorX = img.width / horizontalContent;
  var scaleFactorY = img.height / verticalContent;
  var factor = scaleFactorX / scaleFactorY;
  if (factor > 2 && factor < 3) {
    return default_Scale;
  }
  var finalScale = Math.min(scaleFactorX, scaleFactorY);
  finalScale = Math.min(finalScale, max_scale);
  finalScale = Math.max(finalScale, default_Scale);
  return finalScale + adjustScale;
}
function buildSlider() {
  // Create the main slider
  var mainSlider = new Splide('#splide-product', {
    type: 'loop',
    perPage: 1,
    cover: false,
    pagination: false,
    arrows: false,
    breakpoints: {
      '768': {
        arrows: {
          prev: '<i class="material-icons left"></i>',
          next: '<i class="material-icons right"></i>'
        }
      }
    }
  });

  // Create the thumbnail slider
  var thumbnailSlider = new Splide('#splide-thumbnail', {
    type: 'slide',
    perPage: 3,
    focus: 'center',
    pagination: false,
    cover: true,
    gap: 10,
    isNavigation: true,
    arrows: false,
    breakpoints: {
      '768': {
        perPage: 0
      },
      '600': {
        perPage: 2
      },
      '300': {
        perPage: 1
      }
    }
  }).mount();

  //sync both sliders together
  mainSlider.sync(thumbnailSlider).mount();
  var images = document.querySelectorAll('#splide-product img');
  images.forEach(function (img) {
    img.onload = function () {
      var scale = calculateScale(img);
      img.style.transform = "scale(".concat(scale, ")");
    };
    if (img.complete) img.onload(); // In case the image is already loaded
  });
}

function cleanURL() {
  // Remove anchor from URL
  var uri = window.location.toString();
  if (uri.indexOf("#") > 0) {
    var clean_uri = uri.substring(0, uri.indexOf("#"));
    window.history.replaceState({}, document.title, clean_uri);
  }
}
if (window.location.pathname === '/product') {
  document.addEventListener('DOMContentLoaded', function () {
    cleanURL();
    buildSlider();
  });
  $(document).ajaxComplete(function () {
    cleanURL();
    buildSlider();
  });
}
if ($('body').attr('id') == 'product') {
  document.addEventListener('DOMContentLoaded', function () {
    buildSlider();
  });
  $(document).ajaxComplete(function () {
    buildSlider();
    cleanURL();
  });
}